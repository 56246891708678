import React from "react";

import TopNavigation from "@cloudscape-design/components/top-navigation";
import { useAuthProvider } from "../lib/auth/useAuthProvider";
import { signOutUser } from "../lib/firebase/authUtils";

const i18nStrings = {
  searchIconAriaLabel: "Search",
  searchDismissIconAriaLabel: "Close search",
  overflowMenuTriggerText: "More",
  overflowMenuTitleText: "All",
  overflowMenuBackIconAriaLabel: "Back",
  overflowMenuDismissIconAriaLabel: "Close menu",
};

export default function TopNav() {
  const { user } = useAuthProvider();

  const getUtilities = () => {
    if (_.isEmpty(user)) {
      return [];
    }

    return [
      {
        type: "menu-dropdown",
        text: user.displayName,
        description: user.email,
        iconName: "user-profile",
        onItemClick: ({ detail }) => {
          if (detail.id === "sign_out") {
            signOutUser();
          }
        },
        items: [
          {
            type: "button",
            id: "profile",
            text: "Profile",
            onClick: () => {
              console.log("yoooo hoooo");
            },
          },
          {
            type: "button",
            id: "sign_out",
            text: "Sign out",
            onClick: () => {
              console.log("yoooo hoooo");
            },
          },
        ],
      },
    ];
  };

  return (
    <TopNavigation
      i18nStrings={i18nStrings}
      identity={{
        href: "#",
        title: "",
        logo: {
          src: "https://nebham.com/nebham_logo_white.png",
          alt: "Nebham Logo",
        },
      }}
      utilities={getUtilities()}
    />
  );
}
