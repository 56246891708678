import { AuthContext } from "./context";
import { useEffect, useMemo, useState } from "react";

import { auth } from "../firebase";
import { signOutUser } from "../firebase/authUtils";
import { ALLOWED_USER_EMAILS } from "../../admin-api/config";

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authStateChanged);
    return () => unsubscribe();
  }, []);

  const authStateChanged = async (authState) => {
    if (!authState) {
      setUser(null);
      setLoading(false);
      return;
    }

    // verify the user
    if (_.includes(ALLOWED_USER_EMAILS, authState.email)) {
      setUser({
        ...authState,
        uid: authState.uid,
        email: authState.email,
      });
    } else {
      signOutUser().then(() => alert("User not allowed."));
    }

    setLoading(false);

    return authState;
  };

  const value = useMemo(
    () => ({
      user,
      loading,
      isUserNotLoaded: () => _.isEmpty(auth.currentUser),
    }),
    [user, loading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
