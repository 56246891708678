import lodashSplit from "lodash/split";

export const APP_VERSION = "v1.0";
export const APP_CODE = "nebham-hub-web";

export const SENTRY_DSN_KEY = process.env.NEXT_PUBLIC_SENTRY_DSN_KEY;

export const ALLOWED_USER_EMAILS = lodashSplit(
  process.env.NEXT_PUBLIC_ALLOWED_USER_EMAILS,
  ","
);

export const API_MODE = process.env.NEXT_PUBLIC_API_MODE;
