import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import { auth } from "./index";

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    setPersistence(auth, browserLocalPersistence).then(() => {
      return signInWithPopup(auth, googleProvider);
    });
  } catch (err) {
    console.error(err);
  }
};

export const signOutUser = async () => {
  await signOut(auth);
};

export const getAuthToken = async () => {
  try {
    const token = await auth.currentUser?.getIdToken();
    if (_.isUndefined(token) || _.isEmpty(token)) {
      console.log(auth);
      throw "Undefined/Empty token.";
    }

    return `Bearer ${token}`;
  } catch (err) {
    console.log("getAuthToken", err);

    throw `getAuthToken Error: ${err.message}`;
  }
};
