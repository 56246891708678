import React from "react";

import _ from "lodash";
import "@cloudscape-design/global-styles";

import "../styles/base.scss";
import TopNav from "../layouts/TopNav";
import { AuthProvider } from "../lib/auth/AuthProvider";
import { useAuthProvider } from "../lib/auth/useAuthProvider";
import LoginPage from "../lib/auth/LoginPage";
import NoSSRWrapper from "../lib/NoSSRWrapper";

function AuthenticatedApp({ Component, pageProps }) {
  const { isUserNotLoaded, loading, user } = useAuthProvider();

  if (isUserNotLoaded() || (!loading && _.isEmpty(user))) {
    return <LoginPage />;
  }

  return (
    <>
      <TopNav />
      <Component {...pageProps} />
    </>
  );
}

function TheApp({ Component, pageProps }) {
  return (
    <NoSSRWrapper>
      <AuthProvider>
        <AuthenticatedApp Component={Component} pageProps={pageProps} />
      </AuthProvider>
    </NoSSRWrapper>
  );
}

export default TheApp;
