import { useContext } from "react";
import { AuthContext } from "./context";

export function useAuthProvider() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("'useAuth' must be a child of <AuthProvider/>");
  }

  return context;
}
